export function sharePending<F extends () => Promise<any>>(fn: F): F {
  return sharePendingBy(() => 'pending', fn);
}

export function sharePendingBy<F extends (...args: any[]) => Promise<any>>(
  makeKey: (...args: Parameters<F>) => string,
  fn: F,
): F {
  const cache: Record<string, Promise<any> | undefined> = {};

  return ((...args: Parameters<F>) => {
    const key = makeKey(...args);
    cache[key] =
      cache[key] ||
      fn(...args).finally(() => {
        delete cache[key];
      });
    return cache[key];
  }) as F;
}
