import { Observable, of, retry } from 'rxjs';

import { RestSDKError, parseJson } from 'api/utils/RestSDK';
import { makeCallSpy } from 'utils/rxjs';
import { sharePending } from 'utils/promise';
import { logger } from 'core/logger';

import { BASE_URL, authStorage, getPrivateHeaders, getRefreshHeaders } from '../constants';
import { JsonResponses } from '../types';

export function withAutoRefresh<F extends (...args: any[]) => Observable<any> | Promise<any>>(
  loadFn: F,
): F {
  const wrappedFn = (...args: any[]) => {
    const loading = loadFn(...args);

    if ('then' in loading) {
      return loading.catch(async error => {
        const hasRefreshHeaders = 'Authorization' in getRefreshHeaders();

        if (hasRefreshHeaders && error instanceof RestSDKError && error.response.status === 401) {
          await refreshSession();
          return loadFn(...args);
        }

        throw error;
      });
    }

    return loading.pipe(
      retry({
        resetOnSuccess: true,
        async delay(error, retryCount) {
          const hasRefreshHeaders = 'Authorization' in getRefreshHeaders();

          if (
            hasRefreshHeaders &&
            retryCount === 1 &&
            error instanceof RestSDKError &&
            error.response.status === 401
          ) {
            await refreshSession();
            return of(true);
          }

          throw error;
        },
      }),
    );
  };
  return wrappedFn as F;
}

type URL = '/api/auth/session/refresh';

export const [refreshSession, { failedCalls$: refreshSessionFailed$ }] = makeCallSpy(
  sharePending(async (): Promise<void> => {
    const refreshToken = authStorage.getItem('refresh');
    const refreshResponse = await fetch(`${BASE_URL}/auth/session/refresh`, {
      headers: { ...getRefreshHeaders(), rid: 'session' },
      method: 'POST',
    });

    if (!refreshResponse.ok) {
      authStorage.set({ access: null, refresh: null });
    }

    try {
      await parseJson<JsonResponses<URL, 'post'>>(refreshResponse);
    } catch (error) {
      const response =
        error instanceof RestSDKError ? await error.response.json().catch(() => ({})) : {};

      if ('message' in response && response.message === 'token theft detected') {
        logger.captureError(new Error(`Token theft detected with refresh token "${refreshToken}"`));
      }
      throw error;
    }

    const temporaryAccessToken = refreshResponse.headers.get('St-Access-Token');
    const newRefreshToken = refreshResponse.headers.get('St-Refresh-Token');

    if (!temporaryAccessToken || !newRefreshToken) {
      throw new Error(`Response doesn't contain required headers`);
    }

    authStorage.set({ access: temporaryAccessToken, refresh: newRefreshToken });

    const userResponse = await fetch(`${BASE_URL}/v1/user`, {
      headers: getPrivateHeaders(),
    });

    const accessToken = userResponse.headers.get('St-Access-Token');

    if (!accessToken) {
      authStorage.set({ access: null, refresh: null });
      throw new Error(`Response doesn't contain required headers`);
    }

    authStorage.setItem('access', accessToken);
  }),
);
