import React from 'react';
import { useForm } from 'react-hook-form';
import { CircularProgress } from '@mui/material';
import { useTimer } from 'react-timer-hook';
import { useAsyncFn } from 'react-use';

import { colors, styled } from 'core/styles';
import { getErrorMsg } from 'utils/getErrorMsg';
import type { IncorrectCodeResponse, RestartFlowResponse } from 'api/modules/apostro-rest/auth';
import { authApi } from 'api/modules';

import {
  ErrorMessage,
  Form,
  Title,
  Message,
  Root,
  SubmitButton,
  TitleBox,
  TextFieldInput,
} from './styles';
import { PendingUser } from '../types';

type Props = {
  pendingUser: PendingUser;
  onResetEmailClick: () => void;
  onSuccessfulOTPSending: (email: string) => void;
};

export type SendOTPFormData = {
  code: string;
};

function getResendTime(): Date {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 59);
  return time;
}

const INCORRECT_CODE_STATUS: IncorrectCodeResponse['status'] = 'INCORRECT_USER_INPUT_CODE_ERROR';
const RESTART_FLOW_STATUS: RestartFlowResponse['status'] = 'RESTART_FLOW_ERROR';
const MAX_CODE_LENGTH = 6;

export function SendOTPForm(props: Props) {
  const { pendingUser, onResetEmailClick } = props;
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    reset: resetForm,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<SendOTPFormData>({
    defaultValues: {
      code: '',
    },
  });
  const inputValue = watch('code');

  const {
    seconds,
    totalSeconds,
    restart: restartResendTimer,
  } = useTimer({ expiryTimestamp: getResendTime() });
  const isTimerCompleted = totalSeconds === 0;

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    clearErrors('root');
    if (e.target.value.length >= MAX_CODE_LENGTH) {
      setValue('code', e.target.value.slice(0, MAX_CODE_LENGTH));
      onSubmit();
    }
  };

  const onSubmit = handleSubmit(async data => {
    try {
      clearErrors('root');
      const response = await authApi.sendOTP({
        deviceId: pendingUser.deviceId,
        preAuthSessionId: pendingUser.preAuthSessionId,
        userInputCode: data.code,
      });
      if ('user' in response) {
        props.onSuccessfulOTPSending(response.user.email);
      } else {
        setError('root', { type: response.status });
      }
    } catch (error) {
      setError('root', { message: getErrorMsg(error) });
    }
  });

  const [resending, handleResendCodeClick] = useAsyncFn(async () => {
    try {
      clearErrors('root');
      await authApi.resendOTP({
        deviceId: pendingUser.deviceId,
        preAuthSessionId: pendingUser.preAuthSessionId,
      });
      resetForm();
      restartResendTimer(getResendTime());
    } catch (error) {
      setError('root', { message: getErrorMsg(error) });
    }
  }, [
    clearErrors,
    pendingUser.deviceId,
    pendingUser.preAuthSessionId,
    resetForm,
    restartResendTimer,
    setError,
  ]);

  return (
    <Root>
      <TitleBox>
        <Title>Please type in the code from email</Title>
        <span>
          That we’ve sent to {pendingUser.email} ·{' '}
          <FormLink onClick={onResetEmailClick}>Change&nbsp;email</FormLink>
        </span>
      </TitleBox>
      <CustomForm onSubmit={onSubmit}>
        <CodeCharacters inputValue={inputValue} />
        <TextFieldInputCode
          fullWidth
          required
          isError={!!errors.code}
          {...register('code', { required: true, onChange })}
        />
        <SubmitButton
          type="submit"
          disabled={!!errors.code || isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
        >
          {!isSubmitting && 'Authorise'}
        </SubmitButton>
        {!errors.root && (
          <Message>
            Didn’t receive the code?{' '}
            {isTimerCompleted ? (
              <FormLink onClick={handleResendCodeClick}>Re-send now</FormLink>
            ) : (
              <TimerBox>
                Re-send in <TimeBox>{seconds}</TimeBox> seconds
              </TimerBox>
            )}
            {resending.loading && <ResendProgress size="1em" color="inherit" sx={{}} />}
          </Message>
        )}
        {errors.root?.type === INCORRECT_CODE_STATUS && (
          <ErrorMessage>
            The code you’ve entered is incorrect. Please try again or{' '}
            <FormLink onClick={handleResendCodeClick}>request a new one</FormLink>
          </ErrorMessage>
        )}
        {errors.root?.type === RESTART_FLOW_STATUS && (
          <ErrorMessage>
            You have exceeded the maximum number of attempts. Try another email.{' '}
            <FormLink onClick={onResetEmailClick}>Change email</FormLink>
          </ErrorMessage>
        )}
        {errors.root?.message && <ErrorMessage>{errors.root.message}</ErrorMessage>}
      </CustomForm>
    </Root>
  );
}

function CodeCharacters({ inputValue }: { inputValue: string }) {
  return (
    <Characters>
      {Array.from(inputValue.slice(0, MAX_CODE_LENGTH)).map((ch, i) => (
        <Character key={i}>{ch}</Character>
      ))}
      {new Array(Math.max(0, MAX_CODE_LENGTH - inputValue.length)).fill(null).map((_, i) => (
        <PlaceholderCharacter key={i}>•</PlaceholderCharacter>
      ))}
    </Characters>
  );
}

const CustomForm = styled(Form)({
  position: 'relative',
});

const Characters = styled('div')({
  position: 'absolute',
  display: 'flex',
  flex: '0 0 20%',
  width: '100%',
  padding: 14,

  '@media (min-width: 400px)': {
    paddingLeft: 30,
  },
});

const Character = styled('div')(({ theme }) => ({
  width: 44,
  fontFamily: theme.typography.secondaryFontFamily,
  fontSize: 24,
  letterSpacing: 0,
  textAlign: 'center',
  color: colors.black,
}));

const PlaceholderCharacter = styled(Character)({
  color: colors.blackOpacity[24],
});

const TextFieldInputCode = styled(TextFieldInput)(({ theme }) => ({
  marginBottom: 32,
  '& .MuiInputBase-root, & input:autofill': {
    fontFamily: theme.typography.secondaryFontFamily,
    fontSize: 24,
    letterSpacing: 30,
  },
  '& input': {
    padding: 14,
    textAlign: 'left',
    color: 'transparent',
    caretColor: colors.black,

    '@media (min-width: 400px)': {
      paddingLeft: 30,
    },
  },
}));

const FormLink = styled('span')({
  textDecoration: 'underline',
  transition: 'opacity 200ms',
  cursor: 'pointer',

  '&:hover': {
    opacity: 0.7,
  },
});

const ResendProgress = styled(CircularProgress)({
  display: 'inline-block',
  marginLeft: 4,
  marginBottom: '-0.2em',
});

const TimerBox = styled('span')({
  color: colors.blackOpacity[68],
});

const TimeBox = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.secondaryFontFamily,
}));
