import { parseJson } from 'api/utils/RestSDK';

import { BASE_URL, headers } from '../constants';
import { JsonResponses, schemas } from '../types';

type URL = '/api/auth/signinup/code/resend';

export type ResendOTPRequest = schemas['ResendCodeRequest'];
export type ResendOTPResponse = schemas['StatusResponse'];

export async function resendOTP(request: ResendOTPRequest): Promise<ResendOTPResponse> {
  const response = await fetch(`${BASE_URL}/auth/signinup/code/resend`, {
    headers: { ...headers, rid: 'passwordless' },
    method: 'POST',
    body: JSON.stringify(request),
  });
  const json = await parseJson<JsonResponses<URL, 'post'>>(response);

  return json;
}
