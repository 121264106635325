import { parseJson } from 'api/utils/RestSDK';

import { BASE_URL, authStorage, headers } from '../constants';
import { JsonResponses, schemas } from '../types';
import { RequestOTPResponse } from './requestOTP';

type URL = '/api/auth/signinup/code/consume';

export type SendOTPParams = {
  userInputCode: string;
} & RequestOTPResponse;

export type SendOTPResponse = SuccessLoginResponse | IncorrectCodeResponse | RestartFlowResponse;

export type SuccessLoginResponse = Pick<schemas['ConsumeCodeResponse'], 'user'>;

export type IncorrectCodeResponse = {
  failedCodeInputAttemptCount: number;
  maximumCodeInputAttempts: number;
  status: 'INCORRECT_USER_INPUT_CODE_ERROR';
};

export type RestartFlowResponse = {
  status: 'RESTART_FLOW_ERROR';
};

export async function sendOTP(params: SendOTPParams): Promise<SendOTPResponse> {
  const response = await fetch(`${BASE_URL}/auth/signinup/code/consume`, {
    headers: { ...headers, 'st-auth-mode': 'header', rid: 'passwordless' },
    method: 'POST',
    body: JSON.stringify(params),
  });
  const json = await parseJson<JsonResponses<URL, 'post'>>(response);

  const access = response.headers.get('St-Access-Token');
  const refresh = response.headers.get('St-Refresh-Token');

  if (!('user' in json)) {
    if (json.status === 'INCORRECT_USER_INPUT_CODE_ERROR') {
      return json as IncorrectCodeResponse;
    }
    if (json.status === 'RESTART_FLOW_ERROR') {
      return json as RestartFlowResponse;
    }
    throw new Error(`Something went wrong (${json.status})`);
  }

  if (!access || !refresh) {
    throw new Error(`Response doesn't contain required headers`);
  }

  authStorage.set({ access, refresh });

  return json;
}
