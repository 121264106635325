/* eslint-disable class-methods-use-this */
/* eslint-disable no-constructor-return */
import {
  Observable,
  ReplaySubject,
  distinctUntilChanged,
  fromEvent,
  switchMap,
  startWith,
  catchError,
  of,
  map,
  BehaviorSubject,
} from 'rxjs';

import { User } from 'domain/types';
import { IS_BROWSER } from 'env/projectSettings';
import { Storage, localStorageAdapter } from 'core/storage';
import { memoize } from 'utils/decorators';

import { apiErrorInterceptor } from './apiErrorInterceptor';
import {
  RequestOTPResponse,
  SendOTPParams,
  SendOTPResponse,
  loadUser,
  logout,
  refreshSessionFailed$,
  requestOTP,
  sendOTP,
  ResendOTPRequest,
  resendOTP,
  ResendOTPResponse,
} from './apostro-rest/auth';

type AuthStorage = {
  userEmail: string | null;
};

export class AuthApi {
  private storage = new Storage<[AuthStorage]>(
    'auth',
    localStorageAdapter,
    { userEmail: null },
    [],
  );

  public user = new ReplaySubject<User | null>(1);
  public modalIsOpen = new BehaviorSubject<boolean>(false);

  constructor() {
    if (!IS_BROWSER) {
      this.user.next(null);
    } else {
      this.subscribeUserChanges();
      this.subscribeRefreshErrors();
    }

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    return apiErrorInterceptor.getProxiedObj(this);
  }

  public openModal() {
    this.modalIsOpen.next(true);
  }

  public closeModal() {
    this.modalIsOpen.next(false);
  }

  @memoize()
  public getUser$(): Observable<User | null> {
    return this.user.pipe(
      distinctUntilChanged((a, b) => {
        return !!a === !!b && a?.email === b?.email;
      }),
    );
  }

  public async requestOTP(email: string): Promise<RequestOTPResponse> {
    const response = await requestOTP(email);
    return response;
  }

  public async resendOTP(request: ResendOTPRequest): Promise<ResendOTPResponse> {
    const response = await resendOTP(request);
    return response;
  }

  public async sendOTP(params: SendOTPParams): Promise<SendOTPResponse> {
    const response = await sendOTP(params);
    await this.syncUserAuth();
    return response;
  }

  public async logout(): Promise<void> {
    try {
      await logout();
    } finally {
      this.setUser(null);
    }
  }

  private subscribeUserChanges() {
    return fromEvent<StorageEvent>(window, 'storage')
      .pipe(
        startWith(true),
        map(() => this.storage.getItem('userEmail')),
        distinctUntilChanged(),
        switchMap(() => this.syncUserAuth()),
        catchError(() => of(null)),
      )
      .subscribe();
  }

  private subscribeRefreshErrors() {
    return refreshSessionFailed$.pipe(switchMap(() => this.logout())).subscribe();
  }

  private async syncUserAuth(): Promise<User | null> {
    try {
      const user = await loadUser();
      this.setUser(user);
      return user;
    } catch (error) {
      this.setUser(null);
      return null;
    }
  }

  private setUser(user: User | null) {
    const storageEmail = this.storage.getItem('userEmail');

    if (!!user !== !!storageEmail || user?.email !== storageEmail) {
      this.storage.setItem('userEmail', user && user.email);
    }
    this.user.next(user);
  }
}

export const authApi = new AuthApi();

if (IS_BROWSER) {
  (window as any).authApi = authApi;
}
