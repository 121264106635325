import React from 'react';
import { useForm } from 'react-hook-form';
import { CircularProgress } from '@mui/material';

import { getErrorMsg } from 'utils/getErrorMsg';
import { authApi } from 'api/modules';

import { ErrorMessage, Form, Title, Root, SubmitButton, TitleBox, TextFieldInput } from './styles';
import { PendingUser } from '../types';

type Props = {
  onSuccessfulOTPRequest: (pendingUser: PendingUser) => void;
};

export type RequestOTPFormData = {
  email: string;
};

export function RequestOTPForm(props: Props) {
  const { onSuccessfulOTPRequest } = props;
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<RequestOTPFormData>();

  const onSubmit = handleSubmit(async data => {
    try {
      clearErrors('root');
      const response = await authApi.requestOTP(data.email);
      onSuccessfulOTPRequest({ ...response, email: data.email });
    } catch (error) {
      setError('root', { message: getErrorMsg(error) });
    }
  });

  return (
    <Root>
      <TitleBox>
        <Title>Authorise to access your account</Title>
        <span>Keep track of your favourite wallets and markets</span>
      </TitleBox>
      <Form onSubmit={onSubmit}>
        <TextFieldInput
          placeholder="Your email"
          fullWidth
          isError={!!errors.email}
          required
          type="email"
          {...register('email', { required: true, onChange: () => clearErrors('root') })}
        />
        <SubmitButton
          type="submit"
          disabled={!!errors.email}
          endIcon={isSubmitting && <CircularProgress size={15} color="inherit" />}
        >
          Continue
        </SubmitButton>
        {errors.email && (
          <ErrorMessage>It seems you’ve typed in an incorrect email. Please try again</ErrorMessage>
        )}
        {errors.root?.message && <ErrorMessage>{errors.root.message}</ErrorMessage>}
      </Form>
    </Root>
  );
}
