import { parseJson } from 'api/utils/RestSDK';

import { BASE_URL, authStorage, getPrivateHeaders } from '../constants';
import { JsonResponses } from '../types';

type URL = '/api/auth/signout';

export async function logout(): Promise<void> {
  const response = await fetch(`${BASE_URL}/auth/signout`, {
    headers: { ...getPrivateHeaders(), rid: 'session' },
    method: 'POST',
  });
  authStorage.set({ access: null, refresh: null });
  await parseJson<JsonResponses<URL, 'post'>>(response);
}
