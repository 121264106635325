import { parseJson } from 'api/utils/RestSDK';
import { sharePending } from 'utils/promise';
import { User } from 'domain/types';

import { BASE_URL, getPrivateHeaders } from '../constants';
import { JsonResponses } from '../types';
import { withAutoRefresh } from './refreshSession';

type URL = '/api/v1/user';

export const loadUser = sharePending(
  withAutoRefresh(async (): Promise<User> => {
    const response = await fetch(`${BASE_URL}/v1/user`, {
      headers: getPrivateHeaders(),
    });

    const user = await parseJson<JsonResponses<URL, 'get'>>(response);

    return {
      email: user.email,
      shortName: user.email.split('@')[0],
    };
  }),
);
