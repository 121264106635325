import { ButtonBase, Modal } from '@mui/material';
import React, { useCallback, useState } from 'react';

import { colors, styled } from 'core/styles';
import { AddressIcon, Loading, NextLink } from 'components';
import { useSubscribable } from 'utils/react';
import { authApi } from 'api/modules';
import { useRouteUtils } from 'routes';

import { RequestOTPForm, SendOTPForm } from './components';
import { PendingUser } from './types';
import formBg from './components/images/formBg.webp';

export function AuthButton() {
  const [pendingUser, setPendingUser] = useState<PendingUser>();
  const { redirectTo } = useRouteUtils();

  const userRD = useSubscribable(() => authApi.getUser$(), [], { clientOnly: true });
  const modalIsOpen =
    useSubscribable(() => authApi.modalIsOpen, [], { clientOnly: true }).toNullable() || false;

  const handleSignOutButtonClick = useCallback(() => {
    authApi.logout();
    setPendingUser(undefined);
  }, []);

  const onResetEmailClick = () => {
    setPendingUser(undefined);
  };

  return (
    <Loading data={userRD} loader={null}>
      {user => (
        <>
          <AuthBox>
            {user ? (
              <>
                <UserInfo href={redirectTo.globalProfile().href}>
                  <IconBox>
                    <AddressIcon address={user.email} fontSize="inherit" />
                  </IconBox>
                  <UserName>{user.shortName}</UserName>
                </UserInfo>
                <SignOutButton onClick={handleSignOutButtonClick}>Log out</SignOutButton>
              </>
            ) : (
              <SignInButton onClick={authApi.openModal}>My account</SignInButton>
            )}
          </AuthBox>
          <Modal open={modalIsOpen} onClose={authApi.closeModal} keepMounted>
            <FormBox>
              {!pendingUser ? (
                <RequestOTPForm onSuccessfulOTPRequest={setPendingUser} />
              ) : (
                <SendOTPForm
                  pendingUser={pendingUser}
                  onSuccessfulOTPSending={authApi.closeModal}
                  onResetEmailClick={onResetEmailClick}
                />
              )}
            </FormBox>
          </Modal>
        </>
      )}
    </Loading>
  );
}

const AuthBox = styled('div')({
  display: 'flex',
  gap: 6,
});

const UserInfo = styled(NextLink)({
  height: 32,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 8,
  padding: '0 4px',
  borderRadius: 4,
  color: colors.pearlDarkGray,
  background: colors.smokyWhite,
  fontSize: 14,
  transition: 'opacity 200ms',

  '&:hover': {
    opacity: 0.5,
    cursor: 'pointer',
  },
});

const IconBox = styled('div')({
  fontSize: 24,
});

const UserName = styled('span')(({ theme }) => ({
  display: 'none',
  paddingRight: 4,

  [theme.breakpoints.up('mobileMD')]: {
    display: 'inline',
  },
}));

const buttonStyles = {
  height: 32,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 14,
  borderRadius: 100,
  padding: '0 12px',
  textWrap: 'nowrap',
} as const;

const SignInButton = styled(ButtonBase)({
  ...buttonStyles,
  color: colors.pearlDarkGray,
  background: colors.smokyWhite,
  transition: 'opacity 200ms',

  '&:hover': {
    opacity: 0.7,
  },
});

const SignOutButton = styled(ButtonBase)({
  ...buttonStyles,
  color: colors.pearlDarkGray,
  background: colors.smokyWhite,
  transition: 'opacity 200ms',

  '&:hover': {
    opacity: 0.7,
  },
});

const FormBox = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  minHeight: 420,
  padding: '64px 20px 0px 20px',
  borderRadius: 16,
  background: `url(${formBg.src}) 50% / cover no-repeat, ${colors.smokyWhite}`,
  fontSize: 14,

  [theme.breakpoints.up('mobileMD')]: {
    minHeight: 400,
  },

  [theme.breakpoints.up('tabletXS')]: {
    width: 663,
  },
}));
