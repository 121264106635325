export function getShortAddress(
  address: string,
  lengthBeforeElipsis: number = 6,
  lengthAfterElipsis: number = 4,
) {
  return `${address.substr(0, lengthBeforeElipsis)}...${address.substr(
    -lengthAfterElipsis,
    lengthAfterElipsis,
  )}`;
}
