import * as React from 'react';
import Blockies from 'react-blockies';

import { styled, colors, FontSize, getFontSizeCSS } from 'core/styles';

type Props = {
  address: string;
  fontSize?: FontSize;
};

function AddressIcon(props: Props) {
  const { address, fontSize = 'medium' } = props;

  return (
    <Root
      seed={address.toLowerCase()}
      size={8}
      scale={16}
      bgColor={colors.silver}
      fontSize={fontSize}
    />
  );
}

const Root = styled(Blockies, {
  shouldForwardProp: prop => prop !== 'fontSize',
})<{
  fontSize: FontSize;
}>(({ fontSize }) => ({
  display: 'block',
  borderRadius: '0.15em',
  width: `1em !important`,
  height: '1em !important',
  fontSize: getFontSizeCSS({ fontSize }),
}));

export { AddressIcon };
