import { parseJson } from 'api/utils/RestSDK';

import { BASE_URL, headers } from '../constants';
import { JsonResponses, schemas } from '../types';

type URL = '/api/auth/signinup/code';

export type RequestOTPResponse = Pick<
  schemas['SigninupCodeResponse'],
  'deviceId' | 'preAuthSessionId'
>;

export async function requestOTP(email: string): Promise<RequestOTPResponse> {
  const response = await fetch(`${BASE_URL}/auth/signinup/code`, {
    headers: { ...headers, rid: 'passwordless' },
    method: 'POST',
    body: JSON.stringify({ email }),
  });
  const json = await parseJson<JsonResponses<URL, 'post'>>(response);

  if (!('deviceId' in json)) {
    throw new Error(json.message || 'Something went wrong');
  }

  return json;
}
