import { Button, TextField } from '@mui/material';

import { colors, styled } from 'core/styles';

export const Root = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 40,
});

export const TitleBox = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 8,
  fontSize: 14,
});

export const Title = styled('span')({
  fontSize: 24,
});

export const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 8,
  width: '100%',
  maxWidth: 320,
});

export const TextFieldInput = styled(TextField, {
  shouldForwardProp: prop => prop !== 'isError',
})<{
  isError: boolean;
}>(({ isError }) => ({
  color: colors.pearlDarkGray,
  '& .MuiInputBase-root': {
    background: isError ? colors.brightRedOrange[16] : colors.blackOpacity[6],
    border: `1px solid ${isError ? colors.brightRedOrange[16] : colors.whiteOpacity[4]}`,
    borderRadius: 8,
    fontSize: 14,
    '&.Mui-focused': {
      background: isError ? colors.brightRedOrange[16] : colors.blackOpacity[4],
    },
  },
  '& input': {
    display: 'block',
    padding: 13,
    textAlign: 'center',

    '&::placeholder': {
      textAlign: 'center',
      color: isError ? colors.brightRedOrange[100] : 'inherit',
    },
  },
  '& fieldset': {
    border: 'none',
  },
}));

export const Message = styled('p')({
  alignSelf: 'center',
  margin: 0,
  fontSize: 14,
  textAlign: 'center',
  marginBottom: 44,
});

export const ErrorMessage = styled(Message)({
  fontSize: 12,
  color: colors.brightRedOrange[100],
  marginBottom: 44,
});

export const SubmitButton = styled(Button)({
  width: '100%',
  padding: 11.8,
  marginBottom: 16,
  background: colors.white,
  borderRadius: 8,
  color: colors.black,
  textTransform: 'none',
  boxShadow: `0px 0px 3px ${colors.silver}`,

  '&.Mui-disabled': {
    color: colors.lightGray[100],
  },

  '&:hover': {
    background: colors.white,
    opacity: 0.6,
  },
});
